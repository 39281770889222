import './App.css';

function App() {
  return (
    <div className='container-top'>
      <div className='contactButton'>
        <a href="mailto:akashrishimittal@gmail.com">
          <img src="/assets/WhatsApp_icon.png" style={{height: '100%'}} />
        </a>
      </div>
      <div className='block first-block'></div>
      <div className='block second-block'></div>
      <div className='block third-block'>
        <div className='block-image-container'>
          <img src="/assets/showing-middle-class.png" className='block-image' />
        </div>
        <div className='block-image-container'>
          <img src="/assets/showing-middle-class-data.png" className='text-image' />
        </div>
      </div>
      <div className='block fourth-block'>
        <div className='block-image-container'>
          <img src="/assets/couple-sharing-interests.png" className='block-image' />
        </div>
        <div className='block-image-container'>
          <img src="/assets/couple-sharing-interests-data.png" className='text-image' />
        </div>
      </div>
      <div className='block fifth-block'>
        <div className='block-image-container'>
          <img src="/assets/couple-laughing.png" className='block-image' />
        </div>
        <div className='block-image-container'>
          <img src="/assets/couple-laughing-data.png" className='text-image' />
        </div>
      </div>
      <div className='block sixth-block'>
        <div className='block-image-container'>
          <img src="/assets/couple-angry.webp" className='block-image' />
        </div>
        <div className='block-image-container'>
          <img src="/assets/couple-angry-data.png" className='text-image' />
        </div>
      </div>
      <div className='block seventh-block'>
        <div className='two-images-wrapper'>
          <div className='image-first-wrapper'>
            <img src="/assets/wife-asking-money-waste.png" className='block-image-double' />
          </div>
          <div className='image-second-wrapper'>
            <img src="/assets/wife-asking-money-for-good-cause.png" className='block-image-double' />
          </div>
        </div>
      </div>
      <div className='block eighth-block'>
        <div className='block-image-container'>
          <img src="/assets/couple-planting-trees.webp" className='block-image' />
        </div>
        <div className='block-image-container'>
          <img src="/assets/couple-planting-trees-data.png" className='text-image' />
        </div>
      </div>
      <div className='block ninth-block'>
        <div className='block-image-container'>
          <img src="/assets/couple-playing-with-animals.webp" className='block-image' />
        </div>
        <div className='block-image-container'>
          <img src="/assets/couple-playing-with-animals-data.png" className='text-image' />
        </div>
      </div>
      <div className='block tenth-block'>
        <div className='block-image-container'>
          <img src="/assets/couple-decorating-cottage.webp" className='block-image' />
        </div>
        <div className='block-image-container'>
          <img src="/assets/couple-decorating-cottage-data.png" className='text-image' />
        </div>
      </div>
      <div className='block eleventh-block'>
        <div className='block-image-container'>
          <img src="/assets/father-convincing-man-to-marry.png" className='block-image' />
        </div>
        <div className='block-image-container'>
          <img src="/assets/father-convincing-man-to-marry-data.png" className='text-image' />
        </div>
      </div>
      <div className='block twelveth-block'>
        <div className='twelveth-block-wrapper'>
          <img src="/assets/wife-teaching-village-women.webp" className='twelveth-block-image' />
          <img src="/assets/man-teaching-kids.webp" className='twelveth-block-image' />
        </div>
        <div className='twelveth-block-image-container'>
          <img src="/assets/wife-teaching-village-women-data.png" className='text-image' />
        </div>
      </div>
      <div className='block twelveth-part-block'>
        <div className='block-image-container'>
          <img src="/assets/couple-working.webp" className='block-image' />
        </div>
        <div className='block-image-container'>
          <img src="/assets/couple-working-data.png" className='text-image' />
        </div>
      </div>
      <div className='block thirteenth-block'>
        <div className='block-image-container'>
          <img src="/assets/treating-injured-puppy.webp" className='block-image' />
        </div>
        <div className='block-image-container'>
          <img src="/assets/treating-injured-puppy-data.png" className='text-image' />
        </div>
      </div>
      <div className='block fourteenth-block'>
        <div className='block-image-container'>
          <img src="/assets/couple-praying.webp" className='block-image' />
        </div>
        <div className='block-image-container'>
          <img src="/assets/couple-praying-data.png" className='text-image' />
        </div>
      </div>
      <div className='block fifteenth-block'>
        <div className='block-image-container'>
          <img src="/assets/happy-family-silhouette.png" className='block-image' />
        </div>
        <div className='block-image-container'>
          <img src="/assets/happy-family-silhouette-data.png" className='text-image' />
        </div>
      </div>
      <div className='block sixteenth-block'>
        <div className='block-image-container'>
          <img src="/assets/happy-family.webp" className='block-image' />
        </div>
        <div className='sixteenth-block-image-container'>
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfQ3D3l5PZndlVjGZhNGDLlMUOM4yyYA7n9xzhcsBwbpd60yw/viewform?embedded=true" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
      </div>
    </div>
  );
}

export default App;
